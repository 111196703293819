<template>
  <div id="footer"
    class="flex items-center justify-center h-20 text-white
    \ bg-red-600 border-red-900 border-t"
  >
    <p>
      Made with ♥ by <strong>Ben Mefteh</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style>
</style>
