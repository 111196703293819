<template>
  <input class="
  \ h-8 px-1 py-2 leading-tight text-gray-700 border rounded shadow
  \ appearance-none focus:outline-none focus:shadow-outline" type="text">
</template>

<script>
export default {
  name: 'SearchBox',
}
</script>
