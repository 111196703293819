<template>
  <div>
    <img id="cover-img"
         class="object-cover w-full"
         src="../assets/covers/rooftops.png"
         alt="man-onrope">
  </div>
</template>

<script>
export default {
  name: 'CoverImage',
}
</script>

<style>
#cover-img{
  max-height: 25rem;
}
</style>
